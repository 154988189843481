/*==========  HERO  ==========*/
.hero__container--team {
	background: linear-gradient(
       rgba(20,20,20, .4), 
       rgba(20,20,20, .4)),
       url('/assets/img/hero/YLI_043.jpg');
   	background-size: cover;
  	background-position: center;
  	width: 100vw;
  	height: 60vh;
  	display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 5px $color-primary solid;
}
/*==========  END HERO  ==========*/




/*==========  TEAM CARDS  ==========*/
.team__profile {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	
	&--card {
		display: flex;
		flex-direction: column;
		align-items: center;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); 
		background-color: $color-secondary;
	}

	&--person {
		width: 48%;
		padding: 1rem;
	}
}

.team__profile--img-melanie {
	background: url('/assets/img/team/melanie.jpg');
}
.team__profile--img-patsy {
	background: url('/assets/img/team/patsy.jpg');
}
.team__profile--img-roderick {
	background: url('/assets/img/team/roderick.jpg');
}
.team__profile--img-melonie {
	background: url('/assets/img/team/melonie.jpg');
}
.team__profile--img-emily {
	background: url('/assets/img/team/emily.jpg');
}
.team__profile--img-liz {
	background: url('/assets/img/team/liz.jpg');
}
.team__profile--img-cathy {
	background: url('/assets/img/team/cathy.jpg');
}
.team__profile--img-carissa {
	background: url('/assets/img/team/carissa.jpg');
}
.team__profile--img {
    background-size: cover;
    border: 3px $color-primary solid;
    border-radius:50% 50% 50% 50%;
    width:200px;
    height:200px;
}

.team__body--btn {
	background-color: $color-primary;
	color: $color-light;
	border-radius: 0;
	font-weight: bold;
	
	&:hover {
		background-color: $color-light;
		color: $color-primary;
		border: 1px solid $color-primary;
	}
}

@media (max-width: 992px) {
	.hero__container--team {
	  	height: 50vh;
	}
	.team__profile--card {
		min-height: 428px;
	}
}

@media (max-width: 768px) {
	.team__profile {

		&--person {
			width: 100%;
		}
	}
	.hero__container--team {
	  	height: 400px;
	}
}

@media (max-width: 600px) {
	.hero__container--team {
		height: auto;
  	background: linear-gradient(
       rgba(20,20,20, .4), 
       rgba(20,20,20, .4)),
       url('/assets/img/team/team.jpg');
       background-size: 100%;
	   background-repeat: no-repeat;
	   background-position: center top;
	}
}
/*==========  END TEAM CARDS  ==========*/






/*==========  INDIVIDUAL TEAM MEMBER PAGES  ==========*/
.team-member__bulk-content--container {
	display: flex;
    flex-direction: row;
    align-items: center;
}

.team-member__hero-text {
	display: flex;
    flex-direction: column;
    align-items: center;

	a {
	  	color: $color-dark;
      	font-family: $font-header;
      	font-size: 2rem;
      	border-bottom: 2px $color-dark solid;
      	text-transform: uppercase;
      	text-decoration: none;

      	&:hover {
      		color: $color-primary;
      	}
    }
    h1 {
		color: $color-dark;
	  	font-size: 5rem;    
	  	margin-top: 1.5rem;
	  	padding-left: 1rem;
    }
}

.team-member__img {
	width: 100%;
	height: auto;
	border: 4px $color-primary solid;
	border-radius: 6px;
}

@media (max-width: 992px) {
	.team-member__bulk-content--container {
	    align-items: flex-start;
	}
}

@media (max-width: 768px) {
	.team-member__bulk-content--container {
	    flex-direction: column;
	}
	.team-member__img {
		width: 50%;
		margin-bottom: 1rem;
	}
}
/*==========  END INDIVIDUAL TEAM MEMBER PAGES  ==========*/








