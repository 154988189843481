/*==========  Font Variables  ==========*/
$font-primary				: 'Open Sans', sans-serif;
$font-secondary             : 'Montserrat', sans-serif;
$font-header                : 'Teko', sans-serif;


/*==========  Color Variables  ==========*/
$color-primary              : #F66733;
$color-secondary            : #522D80;
$color-light				: #FFF;
$color-dark					: #000;
