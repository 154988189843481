footer {
	padding: 4rem 4rem 2rem 4rem;
	background-color: $color-secondary;
	border-top: 5px $color-primary solid;
}

.footer__left {
	display: flex;
	flex-direction: column;

	figure {
		width: 260px;
		margin-left: -12px;
	}

	a {
		color: $color-light;
		text-decoration: none;

		&:hover {
			color: $color-primary;
		}
	}
}

.footer-social {
	font-size: 1.5rem;
}

.footer__right--btn {
	background-color: $color-primary;
	border-radius: 0;
	width: 160px;
	color: $color-light;
	font-weight: bold;

	&:hover {
		color: $color-primary;
		background-color: $color-light;
		border: 1px solid $color-primary;
	}
}

.footer__diversity {
	color: darken($color-light, 10%);
	font-size: .8rem;
}