.training__intro {
	text-align: center;
	margin-bottom: 2rem;
}

.training {
	display: flex;
	flex-flow: row wrap;
	&__section {
		width: 48%;
		min-height: 320px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 2rem;
	}
	&__container {
		display: flex;
		flex-flow: row wrap;
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
	&__card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-shadow: 0 2px 8px -2px rgba(0,0,0,0.6);
		border-radius: 3px;
		margin-top: .5rem;
		margin-bottom: .5rem;
		margin-left: .5rem;
		margin-right: .5rem;
		padding: 2rem;
		color: #FFF;
		width: 48%;
	}

	&__color-a {
		background-color: #229BA2;
	}
	&__color-b{
		background-color: #dd645d;
	}
	&__color-c{
		background-color: #61cf98;
	}
	&__color-d{
		background-color: #8b72be;
	}
}

.training__btn {
	background-color: $color-primary;
	border-radius: 0;
	width: 200px;
	color: $color-light;
	font-weight: bold;
	font-size: 14px;
	// margin-bottom: 3rem;

	&:hover {
		color: $color-primary;
		background-color: $color-light;
		border: 1px solid $color-primary;
	}
}

.training__btn--alt {
	z-index: 20;
	position: fixed;
	bottom: 20px;
	left: 43%;
	background-color: #FFF;
	border-radius: 0;
	border: 2px solid #000;
	width: 200px;
	color: #000;
	font-weight: bold;
	font-size: 14px;
	padding: .5rem;

	&:hover {
		color: #FFF;
		background-color: $color-primary;
		border: 2px solid #FFF;
	}
}

.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: 'Read more';
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Read less';
}

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 .5em;
  color: #DDD;
  font-size: .9em;
  line-height: 2;
  border: 1px solid #DDD;
  border-radius: .25em;
}
.read-more-trigger:hover {
  color: #FFF;
  border: 1px solid #FFF;
}






@media (max-width: 1000px) {
	.training {
		&__card {
			width: 100%;
		}
	}
}



@media (max-width: 1411px) {
	.training__btn--alt {
		left: 42%;
	}
}
@media (max-width: 1262px) {
	.training__btn--alt {
		left: 40%;
	}
}
@media (max-width: 952px) {
	.training__btn--alt {
		left: 38%;
	}
}
@media (max-width: 798px) {
	.training__btn--alt {
		left: 36%;
	}
}
@media (max-width: 700px) {
	.training__btn--alt {
		left: 34%;
	}
}
@media (max-width: 539px) {
	.training__btn--alt {
		left: 32%;
	}
}
@media (max-width: 450px) {
	.training__btn--alt {
		left: 28%;
	}
}
@media (max-width: 400px) {
	.training__btn--alt {
		left: 26%;
	}
}