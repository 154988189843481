/*==========  HERO  ==========*/
.hero__container--course {
  background: linear-gradient(
       rgba(20,20,20, .5), 
       rgba(20,20,20, .5)),
       url('/assets/img/hero/course-hero.jpg');
  	background-size: cover;
  	background-position: center center;
  	width: 100vw;
  	height: 50vh;
  	display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 5px $color-primary solid;
}

.hero__container--content-course {
  	display: flex;
    flex-direction: column;
    align-items: center;

    a {
	  	color: $color-light;
      	font-family: $font-header;
      	font-size: 2rem;
      	border-bottom: 2px #FFF solid;
      	text-transform: uppercase;
      	text-decoration: none;

      	&:hover {
      		color: $color-primary;
      	}
    }
    h1 {
		color: $color-light;
		font-family: $font-secondary;
	  	font-size: 4rem;    
	  	margin-top: 1.5rem;
	  	padding-left: 1rem;
	  	text-align: center;
    }
}

// EVERYTHING LESS THAN 992px
@media (max-width: 992px) {
    .hero__container--content-course {

    h1 {
      font-size: 4rem;    
    }
  }
}

// EVERYTHING LESS THAN 768px
@media (max-width: 768px) {
    .hero__container--content-course {

    h1 {
      font-size: 2.5rem;    
    }
  }
}

// EVERYTHING LESS THAN 576px
@media (max-width: 576px) {
    .hero__container--content-course {

    a {
      font-size: 1.6rem;
    }
    h1 {
      font-size: 2rem;    
    }
  }
}
/*==========  END HERO  ==========*/




/*==========  INTRO SECTION  ==========*/
.course__about--title {
	position: absolute;
  	top: 50%;
  	transform: translateY(-50%);
  	font-size: 3rem;
}
.course__back-btn {
	color: $color-primary;
	border: 1px solid $color-primary;
	border-radius: 0;
	font-weight: bold;

	&:hover {
		color: $color-light;
		background-color: $color-primary;
	}
}

.panel {
	display: flex;
	flex-flow: row nowrap;

	&__item {
		height: 330px;
		width: 33.33%;
		display: flex;
		flex-direction: column;
		// align-items: center;
		// justify-content: center;
		color: $color-light;

		h2 {
			font-size: 2.5rem;
			text-align: center;
			padding-top: .8rem;
		}

		ul {
			list-style: none;
			padding: 0;
			width: 100%;

			li {
				font-size: 1rem;
			}
		}
	}
}

.panel--long-copy {
	display: flex;
	flex-flow: row nowrap;

	h1, h2 {
			font-size: 2rem;
		}

	ul {
		li {
			font-size: 1rem;
		}
	}
}

.panel__item--long-copy {
	height: 340px;
	width: 33.33%;
	display: flex;
	flex-direction: column;
	// align-items: center;
	// justify-content: center;
	color: $color-light;

	h2 {
		font-size: 2.5rem;
		text-align: center;
		padding-top: 1rem;
	}

	ul {
		list-style: none;
		padding: 0;
		width: 100%;

		li {
			font-size: 1rem;
		}
	}
}


.panel__item:nth-child(2n), .panel__item--long-copy:nth-child(2n) {
	align-items: center;
	justify-content: center;
}

@media (max-width: 1200px) {
	.panel__item {
		
		h1, h2 {
			font-size: 2rem;
		}

		ul {
			li {
				font-size: 1rem;
			}
		}
	}

	.panel__item--long-copy {
		> h1, h2 {
			font-size: 2rem;
		}

		> ul {
			li {
				font-size: .9rem;
			}
		}
	}
}

// @media (max-width: 1050px) {
// 	.panel__item {
		
// 		h1, h2 {
// 			font-size: 2rem;
// 		}

// 		ul {
// 			li {
// 				font-size: 1rem;
// 			}
// 		}
// 	}
// }

@media (max-width: 992px) {
	.panel {
		flex-flow: row wrap;

		&__item {
			width: 100%;

			h1, h2 {
				font-size: 3rem;
			}

			ul {
				li {
					font-size: 1.4rem;
				}
			}
		}
	}

	// .panel__item {
		
	// 	h1 {
	// 		font-size: 1.5rem;
	// 	}

	// }

	.panel--long-copy {
		flex-flow: row wrap;
	}

	.panel__item--long-copy {
		width: 100% !important;

		> h1, h2 {
			font-size: 3rem;
		}

		> ul {
			li {
				font-size: 1.3rem;
			}
		}
	}

}

@media (max-width: 768px) {
	// .panel {
	// 	flex-flow: row wrap;

	// 	&__item {
	// 		width: 100%;

	// 		h1, h2 {
	// 			font-size: 3rem;
	// 		}

	// 		ul {
	// 			li {
	// 				font-size: 1.4rem;
	// 			}
	// 		}
	// 	}
	// }

	.panel__item--long-copy {
		width: 100% !important;

		> h1, h2 {
			font-size: 3rem;
		}

		> ul {
			li {
				font-size: 1rem;
			}
		}
	}

}

@media (max-width: 576px) {
	.course__about--title {
	  	font-size: 2rem;
	}

	.course__back-btn {
		font-size: .8rem;
	}

	.panel__item--long-copy {
		> h1, h2 {
			font-size: 2rem;
		}

		> ul {
			li {
				font-size: 1rem;
			}
		}
	}
}
/*==========  END INTRO SECTION  ==========*/




/*==========  REQUEST A QUOTE SECTION  ==========*/
.request-quote__btn {
	background-color: $color-primary;
	color: $color-light;
	border-radius: 0;
	// width: 35%;
	height: 60px;
	font-size: 1rem;
	font-weight: bold;

	&:hover {
		background-color: $color-light;
		color: $color-primary;
		border: 1px solid $color-primary;
	}
}

@media (max-width: 992px) {
	.request-quote__btn {
		width: 100%;
		font-size: 1.2rem;
	}
}

@media (max-width: 576px) {
	.panel__item {
		
		h1, h2 {
			font-size: 2rem;
		}

		ul {
			li {
				font-size: .9rem;
			}
		}
	}
}
/*==========  END REQUEST A QUOTE SECTION  ==========*/






