/*==========  HERO  ==========*/
.hero__container--course-catalog {
	background: linear-gradient(
       rgba(20,20,20, .4), 
       rgba(20,20,20, .4)),
       url('/assets/img/hero/course-catalog-hero.jpg');
}
/*==========  END HERO  ==========*/


/*==========  COURSES  ==========*/
.course-catalog__container {
	margin-bottom: 2rem;
}

.course-catalog__card {
	// background-image: linear-gradient(45deg, transparent 20%, black 25%, transparent 25%), 
 //                    linear-gradient(-45deg, transparent 20%, black 25%, transparent 25%), 
 //                    linear-gradient(-45deg, transparent 75%, black 80%, transparent 0),
 //                    radial-gradient(gray 2px, transparent 0);
 //  background-size: 30px 30px, 30px 30px;
	background-color: $color-primary;
	color: $color-light;
	min-height: 168px; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	// background-image: repeating-linear-gradient(to bottom, transparent, transparent 5px, $color-secondary 5px, $color-secondary 10px),
 //                    repeating-linear-gradient(to right, $color-primary, $color-primary 5px, $color-secondary 5px, $color-secondary 10px);

	&:hover {
		background-image: none;
		background-color: $color-light;
		border: 4px solid $color-primary;
		color: $color-primary;
	}
}

.course-catalog__header--title {
	font-size: 2.2rem;
}

.course-catalog__body--text {	
	overflow: hidden;
   	text-overflow: ellipsis;
   	display: -webkit-box;
   	-webkit-box-orient: vertical;
   	-webkit-line-clamp: 3; /* number of lines to show */
   	line-height: 2;        /* fallback */
  	max-height: 6;       /* fallback */
}

.course-catalog__body--btn {
	background-color: $color-primary;
	color: $color-light;
	border-radius: 0;
	font-weight: bold;
	width: 50%;
	
	&:hover {
		background-color: $color-light;
		color: $color-primary;
		border: 1px solid $color-primary;
	}
}

.registered-trademark {
	margin-top: 1.7rem;
}

.blah::after {
	content: "®";
}
/*==========  END COURSES  ==========*/

@media (max-width: 992px) {
	.course-catalog__card{
		min-height: 134px;
	}
}

@media (max-width: 767px) {
	.course-catalog__card{
		min-height: 134px;
	}
}