/*==========  HERO  ==========*/
.hero__container--impact {
	background: linear-gradient(
       rgba(20,20,20, .4), 
       rgba(20,20,20, .4)),
       url('/assets/img/hero/our-impact-hero.jpg');
}
/*==========  END HERO  ==========*/

.impact__top-left--photo {
	width: 100%;
	height: 640px;
	background-image: url('/assets/img/interior/thinkshops-2.jpg');
	background-size: 100% 100%;
  	background-position: center;
  	background-repeat: no-repeat;
}

.impact__top-right--text-photo {
	display: flex;
	flex-direction: column;

	p {
		width: 100%;
		color: $color-light;
		background-color: $color-primary;
		padding: 3rem 1rem 3rem 1rem;
	}
	figure {
		width: 100%;
	}
}

.impact__middle--text {
	width: 100%;
	color: $color-light;
	background-color: $color-secondary;
	padding: 3rem 8rem 3rem 8rem;
}

.impact__middle--photo {
	height: 500px;
	background-image: url('/assets/img/interior/our-impact--3c.jpg');
	background-size: 100% 100%;
  	background-position: center;
  	background-repeat: no-repeat;
}

.impact__bottom--text {
	display: flex;
	align-items: center;
	width: 100%;
	height: 456px;
	color: $color-light;
	background-color: $color-primary;
	padding: 3rem 2rem 3rem 2rem;
}

.impact__bottom--photo {
	height: 456px;
	background-image: url('/assets/img/interior/our-impact--4b.jpg');
	background-size: 100% 100%;
  	background-position: center;
  	background-repeat: no-repeat;
}

.testimonial-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
}

.fa-quote-left {
	margin-top: 2.5rem;
	font-size: 4rem;
}

.testimonial {
	box-shadow: 0 2px 8px -2px rgba(0,0,0,0.6);
	padding: 1rem;
	margin-top: 2rem;
	width: 85%;
}


@media (max-width: 1200px) {
	.impact__top-left--photo {
		height: 629px;
	}

	.impact__bottom--text {
		height: 94%;
	}

	.impact__bottom--photo {
		background-image: url('/assets/img/interior/our-impact--4bb.jpg');

	}
}

@media (max-width: 991px) {
	.impact__top-left--photo {
		height: 643px;
	}

	.impact__middle--photo {
		height: 350px;
	}
	.impact__bottom--photo {
		height: 350px;
	}
}

@media (max-width: 767px) {
	.impact__middle--text {
		padding: 3rem 1rem 3rem 1rem;
	}
	.impact__middle--photo {
		height: 270px;
	}
	.impact__bottom--photo {
		height: 336px;
	}
}

@media (max-width: 650px) {
	.impact__middle--photo {
		height: 314px;
	}
	
}

@media (max-width: 500px) {
	.impact__top-left--photo {
		height: 450px;
	}
	.impact__middle--photo {
		height: 200px;
	}
}





