@import "variables";
@import "header";
@import "home";
@import "footer";
@import "about";
@import "training";
@import "impact";
@import "team";
@import "course-catalog";
@import "course";
@import "research-resources";
@import "development-programs";
@import "contact";


body {
	font-family: $font-primary;
	margin-top: 60px;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-header;
}

.color-primary {
  color: $color-primary;
}

a {
  color: $color-primary;
}
a:hover {
  color: $color-secondary;
  text-decoration: none;
}


/*==========  HERO  ==========*/
.hero__container {
  	background-size: cover;
  	background-position: center center;
  	width: 100vw;
  	height: 60vh;
    min-height: 400px;
  	display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 5px $color-primary solid;
}

.hero__container--content {
    margin-top: 8rem;
  	display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
    	color: $color-light;
    	font-family: $font-header;
      font-size: 8rem;
      border-bottom: 2px #FFF solid;
      text-transform: uppercase;
    }
    h4 {
    	color: $color-light;
    	font-family: $font-secondary;
      font-size: 2.5rem;    
      margin-top: 1.5rem;
    }
}

// EVERYTHING LESS THAN 992px
@media (max-width: 992px) {
    .hero__container--content {

    h1 {
      font-size: 6rem;
    }
    h4 {
      font-size: 2rem;    
    }
  }
}

// EVERYTHING LESS THAN 768px
@media (max-width: 768px) {
    .hero__container--content {

    h1 {
      font-size: 2.5rem;
    }
  }
}

// EVERYTHING LESS THAN 620px
@media (max-width: 768px) {
    .hero__container--content {

    h4 {
      font-size: 1.4rem;    
    }
  }
}

// EVERYTHING LESS THAN 576px
@media (max-width: 576px) {
    .hero__container {
      height: 60vw;
      min-height: 200px;
    }

    .hero__container--content {

    h1 {
      font-size: 2.4rem;
    }
    h4 {
      font-size: .9rem;  
      margin-bottom: 4rem;  
    }
  }
}
/*==========  END HERO  ==========*/




/*==========  CUSTOMIZED SOLUTION CTA SECTION  ==========*/
.customized-solution-cta {
  background: linear-gradient(
       rgba(20,20,20, .5), 
       rgba(20,20,20, .5)),
       url('/assets/img/interior/customized-solution-cta.jpg');
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  height: 450px;
  border-top: 5px $color-primary solid;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

      h1 {
        color: $color-light;
        font-size: 6rem;
      }
      a {
        color: $color-light;
        border: 1px solid $color-light;
        border-radius: 0;
        font-size: 1.2rem;
        font-weight: bold;
        width: 25%;

        &:hover {
          background-color: $color-light;
          color: $color-primary;
        }
      }
  }
}

@media (max-width: 992px) { 
  .customized-solution-cta {
    div {

        h1 {
          font-size: 4rem;
        }
        a {
          font-size: 1rem;
          width: 25%;
        }
    }
  }
}
@media (max-width: 635px) { 
  .customized-solution-cta {
    div {

        h1 {
          font-size: 3rem;
        }
        a {
          width: 50%;
        }
    }
  }
}
@media (max-width: 475px) { 
  .customized-solution-cta {
    div {

        h1 {
          font-size: 2.3rem;
        }
    }
  }
}
/*==========  END CUSTOMIZED SOLUTION CTA SECTION  ==========*/



_:-ms-lang(x), .selector { color: red; }
