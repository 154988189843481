/*==========  HERO  ==========*/
.hero__container--research-resources {
	background: linear-gradient(
       rgba(20,20,20, .4), 
       rgba(20,20,20, .4)),
       url('/assets/img/hero/research-resources-hero-b.jpg');
}
/*==========  END HERO  ==========*/

.download-section {
	&__category {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 4rem;
		padding-left: 1rem;
	}

	a {
		color: $color-primary;

		&:hover {
			text-decoration: none;
			color: $color-secondary;
		}
	}
}


.infographic__container {
	width: 100%;
	margin-bottom: 4rem;

	img {
		width: 100%;
	}
}