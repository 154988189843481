/*==========  HERO  ==========*/
.hero__container--contact {
	background: linear-gradient(
       rgba(20,20,20, .4), 
       rgba(20,20,20, .4)),
       url('/assets/img/hero/contact-hero.jpg');
}
/*==========  END HERO  ==========*/


/*==========  PHOTO/INFO  ==========*/
.contact__photo {
	padding: 0;
	// margin-right: .5rem;
	figure {
		margin-bottom: 0;

		img {
			width: 100%;
		}
	}
}

.contact__info {
	display: flex;
	flex-direction: column;
	background-color: $color-primary;
	justify-content: center;
	padding-left: 3rem;
	// margin-left: .5rem;
	
	&--title {
		color: $color-light;
		font-weight: bold;
		font-size: 2.3rem;
		padding-right: 2rem;
		margin-bottom: 3rem;
	}
	&--phone {
		color: $color-light;
		font-size: 1.8rem;
		&:hover {
			color: $color-secondary;
			text-decoration: none;
		}
	}
	&--email {
		color: $color-light;
		font-size: 1.8rem;
		margin-bottom: 3rem;
		&:hover {
			color: $color-secondary;
			text-decoration: none;
		}
	}
	&--availability {
		color: $color-light;
		font-size: 1rem;
	}
}
/*==========  END PHOTO/INFO  ==========*/

@media (max-width: 992px) { 
	.contact__info {
	
		&--title {
			font-size: 2.0rem;
		}
		&--phone {
			font-size: 1.5rem;
		}
		&--email {
			font-size: 1.5rem;
		}
		&--availability {
			font-size: .8rem;
		}
	}
}

@media (max-width: 768px) { 
	.contact__info {
	
		&--title {
			font-size: 1.7rem;
		}
		&--phone {
			font-size: 1.2rem;
		}
		&--email {
			font-size: 1.2rem;
		}
		&--availability {
			font-size: .6rem;
		}
	}
}

@media (max-width: 675px) { 
	.contact__info {
	
		&--title {
			font-size: 1.5rem;
			margin-bottom: 2rem;
		}
		&--phone {
			font-size: 1rem;
		}
		&--email {
			font-size: 1rem;
			margin-bottom: 2rem;
		}
		&--availability {
			font-size: .6rem;
		}
	}
}

@media (max-width: 576px) { 
	.contact__info {
		margin-top: 1rem;
		padding: 1rem;

		&--title {
			font-size: 1.5rem;
			margin-bottom: 2rem;
		}
		&--phone {
			font-size: 1rem;
		}
		&--email {
			font-size: 1rem;
			margin-bottom: 2rem;
		}
		&--availability {
			font-size: .6rem;
		}
	}
}