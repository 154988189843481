/*==========  HERO  ==========*/
// _:-ms-lang(x), video { 
//     width: auto;
// }


video[poster] {
    object-fit: cover;
    width: 100%;
    // height: 100vh;
    min-height: 550px;
    max-height: 75%;
}

.video-content__container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 1;
    color: #fff!important;
    width: 100%;
    padding: 2rem;
    text-align: center;
}

// .hero__container--home {
//   background: linear-gradient(
//        rgba(20,20,20, .5), 
//        rgba(20,20,20, .5)),
//        url('/assets/img/hero/home-hero.jpg');
// }
.hero__container--content-img {
  width: 800px;
  // height: 200px;
  border-bottom: 3px solid #FFF;
}
.hero__container--content-home {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      color: $color-light;
      font-family: $font-secondary;
      font-size: 2.5rem;    
      margin-top: 1.5rem;
    }
}
/*==========  END HERO  ==========*/


.lead__paragraph {
  margin-left: 1rem;
  margin-right: 1rem;
}


/*==========  ICONS  ==========*/
.icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: lighten(#86898C, 30%);
    min-width: 100%;


    div {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      border-top: 8px solid #000;
      border-bottom: 8px solid #000;
      border-right: 8px solid #000;
      min-height: 250px;

      &:hover {
        background-color: #86898C;
        
        a {
          text-decoration: none;
        }

        .icons__img--title {
          color: $color-light;
        }
      }
    }
    
    &__img {
      min-width: 200px;

      &--title {
        color: $color-dark;
        // font-size: 1rem;
      }
    }

    &__about {
      border-right: 8px solid #000;
      border-left: 8px solid #000;
    }
}


// EVERYTHING LESS THAN 844px
@media (max-width: 844px) {
  video[poster] {
    height: 75vh;
  }

  .hero__container--content-home {
    margin-top: 3.2rem;

      h4 {
        font-size: 1.5rem;
      }
  }

  .hero__container--content-img {
    width: 450px;
  }

  .icons {
    flex-flow: row wrap;
    justify-content: center;
  }
  
//   .icons__catalog {
//     width: 50%;
//     height: 420px; 
//     border-bottom: none !important;
//   }
//   .icons__research {
//     display: flex;
//     justify-content: center;
//     border-top: 8px solid #000;
//     width: 100%;
//     a {
//       figure {
//         img {
//           height: 380px;
//           } 
//         } 
//     }
//   }
//   .icons__about {
//     width: 50%;
//     height: 420px; 
//     border-left: 8px solid #000;
//     border-bottom: none !important;
//   }
//   .icons__dev-programs {
//     width: 50%;
//     height: 420px; 
//     border-left: 8px solid #000;
//     border-bottom: none !important;
//   }
// }

// @media (max-width: 732px) {
//   .icons__about, .icons__catalog, .icons__dev-programs, .icons__research {
//     height: 420px; 
//   }
// }

// @media (max-width: 660px) {
//   .icons__about, .icons__catalog, .icons__dev-programs, .icons__research {
//     height: 360px; 
//   }
}

// EVERYTHING LESS THAN 633px
@media (max-width: 633px) {
  video[poster] {
    height: 40vh;
  }

  .hero__container--content-img {
    width: 400px;
  }

  .hero__container--content-home {
    margin-top: 2rem;

      h4 {
        font-size: 1.5rem;
      }
  }

  .lead__paragraph {
    font-size: 1rem;
  }

  .icons {
    align-items: stretch;
  }
  .icons__about {
    flex-grow: 1;
  }
}

@media (max-width: 735px) {
.icons {
    div {
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
    }
    &__about {
      border-top: 8px solid #000 !important;
      border-bottom: none !important;
    }
    &__catalog {
      border-left: 8px solid #000 !important;
    }
    &__research {
      border-left: 8px solid #000;
    }
    &__dev-programs {
      border-right: 8px solid #000 !important;
      border-left: 8px solid #000 !important;
      border-bottom: none !important;
    }
  }
}

// EVERYTHING LESS THAN 424px
@media (max-width: 424px) {
  video[poster] {
    // height: 30vh;
    min-height: 200px;
  }

  .hero__container--content-img {
    width: 250px;
  }

  .hero__container--content-home {
    margin-top: 1rem;

      h4 {
        font-size: 1rem;
      }
  }

  // .icons__about, .icons__catalog, .icons__dev-programs, .icons__research {
  //   height: 450px; 
  // }
}
/*==========  END ICONS  ==========*/




/*==========  QUOTE SECTION  ==========*/
.quote-section {
  padding-top: 8rem;
  padding-bottom: 8rem;

  blockquote {
    color: $color-light;
    font-family: $font-secondary;
    margin: 0 auto;
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .quote-section {
    blockquote {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 576px) {
  .quote-section {
    blockquote {
      font-size: 1rem;
      margin-right: 1rem;
      margin-left: 1rem;
    }
  }
}
/*==========  END QUOTE SECTION  ==========*/