.nav__desktop {
	display: none;
}

#my-header {
	display: block;
}

.nav__desktop--logo-container-a {
	display: inline-block;
	height: 50px;
	img {
		height: 55px;
	}
}
.nav__desktop--logo-container-b {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 64px;
	img {
		height: 70px;
	}
}

.hambuger-custom {
	font-size: 2.2rem;
	color: $color-dark;

	&:hover {
		color: $color-primary;
	}
}

.sub {
	margin-left: 15px !important;
}

@media (min-width: 900px) {
	#my-header {
		display: none !important;
	} 

	.mobile-navigation__wrapper {
		display: none !important;
	}

	.nav__desktop {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		border-bottom: 5px $color-primary solid;
		box-shadow: 0 2px 8px -2px rgba(0,0,0,0.6);
		padding: .8rem; 
		background-color: $color-light;

		&--item-container {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-around;
			align-items: center;
			width: 100%;
			min-width: 70%;
			margin-bottom: 0;
			list-style: none;
			
			> li > span {
				color: #707070;
				text-decoration: none;
			}
			> li > a {
				color: #707070;
				text-decoration: none;

				&:hover {
					color: $color-primary;
				}
			}
		}

		//NAV ITEM WITH CHILDREN
		&--about {
			> ul {
				display: none;
			}
			svg {
				padding-bottom: 2px;
				font-size: 1.5rem;
			}
		}

		&--about:hover {
			position: relative;

			span {
				color: $color-primary;
			}

			> ul {
				display: block;
				position: absolute;
				left: -30px;
				list-style: none;
				width: 250px;
			}
		}

		&--sub-items {
		background-color: $color-light;
		padding: 5px;

		> a {
			display: inline-block;
			color: #707070;
			text-decoration: none;
			width: 100%;
		}

		&:hover {
			background-color: $color-primary;

			a {
				color: #FFF;
			}
		}
		}
		//END NAV ITEM WITH CHILDREN
	}

	.sub-item__about {
		border: 1px solid #707070;
	}
	.sub-item__team {
		border-right: 1px solid #707070;
		border-left: 1px solid #707070;
		border-bottom: 1px solid #707070;
	}
	.sub-item__impact {
		border-right: 1px solid #707070;
		border-left: 1px solid #707070;
		border-bottom: 1px solid #707070;
	}
	.sub-item__yli {
		border-right: 1px solid #707070;
		border-left: 1px solid #707070;
		border-bottom: 1px solid #707070;
	}
}






.mobile-navigation__wrapper {
	position: relative;
	// margin-top: -60px;
	border-bottom: 5px #F66733 solid; 
	box-shadow: 0 2px 8px -2px rgba(0,0,0,0.6); 
	background-color: #FFF; 
	height: 62px;
	position: fixed !important;
}

#menuToggle
{
  display: block;
  // position: relative;
  // top: 50px;
  // left: 50px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: 12px;
  left: 32px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  margin-left: 2rem;
  position: relative;
  
  background: $color-dark;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

#menu
{
  position: absolute;
  width: 350px;
  height: 2000px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  
  background: $color-primary;
  border: 2px $color-light solid;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.6);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

  a {
  	&:hover {
		text-decoration: none;
  	}
  }
}

#menu li
{
  padding: 10px 0;
  font-size: 1.1rem;
  color: $color-light;
  cursor: pointer;
  font-weight: bold;

  &:hover {
	// color: darken($color-primary, 20%);
	color: $color-secondary;
  }
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}