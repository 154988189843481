/*==========  HERO  ==========*/
.hero__container--about {
	background: linear-gradient(
       rgba(20,20,20, .4), 
       rgba(20,20,20, .4)),
       url('/assets/img/hero/home-hero.jpg');
}
/*==========  END HERO  ==========*/

.three-column__icon {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	svg {
		font-size: 5rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		color: $color-primary;
	}
	h2 {
		text-align: center;
	}
}

.about__links {
	color: $color-primary;

	&:hover {
		color: $color-secondary;
	}
}