/*==========  HERO  ==========*/
.hero__container--dev-programs {
	background: linear-gradient(
       rgba(20,20,20, .4), 
       rgba(20,20,20, .4)),
       url('/assets/img/hero/dev-programs-hero.jpg');
}
/*==========  END HERO  ==========*/

/*==========  SUB HERO  ==========*/
.hero__container--dev-programs-sub {
  background: linear-gradient(
       rgba(20,20,20, .5), 
       rgba(20,20,20, .5)),
       url('/assets/img/hero/sub-dev-programs-hero-b.jpg');
  	background-size: cover;
  	background-position: center center;
  	width: 100vw;
  	height: 50vh;
  	display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 5px $color-primary solid;
}

.hero__container--dev-programs-sub-content {
  	display: flex;
    flex-direction: column;
    align-items: center;

    a {
	  	color: $color-light;
      	font-family: $font-header;
      	font-size: 2rem;
      	border-bottom: 2px #FFF solid;
      	text-transform: uppercase;
      	text-decoration: none;

      	&:hover {
      		color: $color-primary;
      	}
    }
    h1 {
		color: $color-light;
		font-family: $font-secondary;
	  	font-size: 4rem;    
	  	margin-top: 1.5rem;
	  	padding-left: 1rem;
    }
}
/*==========  END SUB HERO  ==========*/

/*==========  PROGRAM SECTION  ==========*/
.dev-programs__container {
	display: flex;
	flex-flow: row wrap;
}

.dev-programs__professional, .dev-programs__leadership, .dev-programs__school, .dev-programs__team {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// align-items: center;
	min-height: 400px;
	width: 50%;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

.dev-programs__professional {
	border-top: 4px solid $color-secondary;
	border-bottom: 4px solid $color-secondary;
}
.dev-programs__leadership {
	border-top: 4px solid $color-secondary;
	border-bottom: 4px solid $color-secondary;
	border-right: 4px solid $color-secondary;
}
.dev-programs__school {
	border-right: 4px solid $color-secondary;
}

.dev-programs__title {
	// max-width: 350px;
	margin-top: 3rem;
    margin-left: .75rem;
    margin-bottom: 1rem;

    span {
    	display: inline;
	    text-transform: uppercase;
	    font-size: 2.75rem;
	    line-height: 1.75;
	    background: $color-secondary;
	    box-shadow: 0.75rem 0 0 $color-secondary, -0.75rem 0 0 $color-secondary;
	    color: #fff;
	    padding: .6rem;
    }
}

.dev-programs__btn {
	background-color: $color-primary;
	color: $color-light;
	border-radius: 0;
	font-size: 1.3rem;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 1rem;
	margin-bottom: 3rem;
	width: 45%;
	
	&:hover {
		background-color: $color-light;
		color: $color-primary;
		border: 1px solid $color-primary;
	}
}

@media (max-width: 1115px) {
	.dev-programs__btn {
		width: 75%;
	}
}

@media (max-width: 786px) {
	.hero__container--dev-programs-sub-content {
	    h1 {
		  	font-size: 2.5rem;    
	    }
	}

	.dev-programs__professional, .dev-programs__leadership, .dev-programs__school, .dev-programs__team {
		width: 100%;
	}

	.dev-programs__professional {
		border-right: none;
		border-bottom: 4px solid $color-secondary !important;
	}
	.dev-programs__school {
		border-right: none;
		border-bottom: 4px solid $color-secondary;
	}
	.dev-programs__leadership {
		border-right: none;
		border-bottom: none;
	}
	
	.dev-programs__title {

	    span {
		    font-size: 2.4rem;
	    }
}

	.dev-programs__btn {
		width: 75%;
	}
}


/*==========  END PROGRAM SECTION  ==========*/













